// Graphql
import { useMutation } from "@apollo/client";
import { Box, Button, Flex, Heading, HStack, Text, useToast } from "@chakra-ui/react";
import Cookie from "js-cookie";
import { useRouter } from "next/router";
import React, { Fragment, useState } from "react";
// import { signIn, useSession } from "next-auth/client";
import { GoogleLogin, useGoogleLogout } from "react-google-login";
// import firebase from "firebase/app";
// import "firebase/auth";
// Libraries
import { useForm } from "react-hook-form";
import { HiExclamationCircle } from "react-icons/hi";
// Recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import firebase from "../../Firebase";
import { GOOGLEAUTH, LOGIN } from "../../graphql/Mutations/Auth";
import { authData, classToJoin } from "../../State";
// Components
import { DarkFormControlInput } from "../UI/Form/FormControlInput";
import FormLink from "../UI/Form/FormLink";
import AddToClass from "../UI/Modal/Enroll/AddToClass";
import Toast from "../UI/Toast";
import Signup from "./Signup";

interface loginProps {
  goToReset: any;
  joinAClass: boolean;
  justLogin: boolean;
  disclosure?: any;
}

const Login = ({ goToReset, joinAClass, justLogin, disclosure }: loginProps) => {
  const router = useRouter();

  // Recoil
  const classToJoinData = useRecoilValue(classToJoin);
  const setAuthValue = useSetRecoilState(authData);

  // toast
  const toast = useToast();
  const toastID = React.useRef<any>(null);

  // const [session, loading] = useSession();

  // React hook form initialisation, refer to https://react-hook-form.com/get-started for clarification
  const { register, handleSubmit, errors } = useForm();

  // STate
  const [errorMessage, setErrorMessage] = useState("");

  const [profileObject, setProfileObject] = useState({});
  const [googleIdentification, setGoogleIdentification] = useState("");

  const [showSignup, setShowSignup] = useState(false);
  const [googleErrorMessage, setGoogleErrorMessage] = useState("");
  const [addToClassH, setAddToClass] = useState(false);
  const [loginV, setLoginV] = useState(true);

  // Phone number state
  const [showPhoneNumberInputAfterGoogleSignup, setshowPhoneNumberInputAfterGoogleSignup] = useState(true);

  // const [classPasswordV, setClassPasswordV] = useState(false);
  // const options = {
  // 	// Configure one or more authentication providers
  // 	providers: [
  // 		Providers.Google({
  // 			clientId: process.env.GOOGLE_CLIENT_ID,
  // 			clientSecret: process.env.GOOGLE_CLIENT_SECRET
  // 		})
  // 	]

  // 	// A database is optional, but required to persist accounts in a database
  // 	// database: process.env.DATABASE_URL,
  // };

  // const googleLogin = () => NextAuth(options);

  //============================ GRaphql ========================================

  const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
  const { signOut } = useGoogleLogout({
    clientId,
  });

  // Normal login with email and password
  const [studentLogin, { loading: normalLoading }] = useMutation(LOGIN, {
    onCompleted({ student_login }) {
      const { value, message, student, action } = student_login;

      if (value) {
        firebase.auth().signInWithEmailAndPassword("noreply@coyani.com", "Coyani2020");
        Cookie.set("x-auth-student", student);
        Cookie.set("student_email", student.email);
        Cookie.set("student_name", student.full_name);

        Cookie.set("student_id", student._id);
        Cookie.set("x-auth-type", "email");
        Cookie.set("x-auth-token", message);

        setAuthValue({
          loggedIn: true,
          studentData: student,
        });
        // localStorage.setItem("x-auth-user", JSON.stringify(response.author));

        if (justLogin) {
          // Cookie.set('x-auth-token', message);
          // router.push(`/classes/enrolled`);
          if (typeof window !== "undefined") {
            // router.push(`/classes/enrolled`);
            window.location.href = "/classes/enrolled";
          }
          toastID.current = toast({
            position: "top",
            duration: 7000,
            render: (props) => <Toast title="Logged in successfully" props={props} />,
          });
        }

        if (joinAClass) {
          // Cookie.set('x-auth-token', message);
          setAddToClass(true);
          setLoginV(false);

          // if (classToJoinData.is_private) {
          //   setClassPasswordV(true);
          //   setLoginV(false);
          // } else {
          //   setAddToClass(true);
          //   setLoginV(false);
          // }
        }
      } else {
        setErrorMessage(message);

        if (action === "No email found") {
          toast.update(toastID.current, {
            position: "top",
            duration: 7000,
            render: (props) => <Toast title={message} props={props} />,
          });
          setShowSignup(true);
          setLoginV(false);
        }
      }
    },
  });

  //props to be passed down to signup to choose the current view
  const showProps = { setShowSignup, setLoginV };

  const [googleSignup, { loading: googleSignupLoading }] = useMutation(GOOGLEAUTH, {
    onCompleted({ student_social_auth }) {
      const { value, message, student } = student_social_auth;

      if (value) {
        firebase.auth().signInWithEmailAndPassword("noreply@coyani.com", "Coyani2020");
        Cookie.set("x-auth-token", message);
        Cookie.set("x-auth-student", student);
        Cookie.set("student_email", student.email);
        Cookie.set("student_name", student.full_name);

        Cookie.set("student_id", student._id);
        Cookie.set("x-auth-type", "google");

        setAuthValue({
          loggedIn: true,
          studentData: student,
        });
        // localStorage.setItem("x-auth-user", JSON.stringify(response.author));

        if (justLogin) {
          // router.push(`/classes/enrolled`);
          window.location.href = "/classes/enrolled";
          toast.update(toastID.current, {
            position: "top",
            duration: 7000,
            render: (props) => <Toast title="Signed up successfully" props={props} />,
          });
        }

        if (joinAClass) {
          setAddToClass(true);
          setLoginV(false);
          // if (classToJoinData.is_private) {
          //   setClassPasswordV(true);
          //   setLoginV(false);
          // } else {
          //   setAddToClass(true);
          //   setLoginV(false);
          // }
        }
      } else {
        signOut();
        toast.update(toastID.current, {
          position: "top",
          duration: 7000,
          render: (props) => <Toast title="Something happened, please try again" props={props} />,
        });
        setGoogleErrorMessage(message);
      }
    },
  });

  const signUpHandler = async (profileObj, googleId) => {
    toast.update(toastID.current, {
      position: "top",
      duration: 7000,
      render: (props) => <Toast title="Creating your account..." props={props} />,
    });
    try {
      await googleSignup({
        variables: {
          name: profileObj.name,
          googleId: googleId,
          email: profileObj.email,
          auth_type: "STUDENT_SIGNUP",
        },
      });
    } catch (err) {
      toast.update(toastID.current, {
        position: "top",
        duration: 7000,
        render: (props) => <Toast title="Something happened, please try again" props={props} />,
      });
      signOut();
      return null;
    }
  };

  const [googleLogin, { loading: googleLoading }] = useMutation(GOOGLEAUTH, {
    onCompleted({ student_social_auth }) {
      const { value, message, student, action } = student_social_auth;

      if (value) {
        firebase.auth().signInWithEmailAndPassword("noreply@coyani.com", "Coyani2020");
        setAuthValue({
          loggedIn: true,
          studentData: student,
        });
        Cookie.set("x-auth-student", student);
        Cookie.set("student_email", student.email);
        Cookie.set("student_name", student.full_name);
        Cookie.set("student_id", student._id);

        Cookie.set("x-auth-token", message);
        Cookie.set("x-auth-type", "google");

        if (justLogin) {
          if (typeof window !== "undefined") {
            window.location.href = "/classes/enrolled";
          }
          toast.update(toastID.current, {
            position: "top",
            duration: 7000,
            render: (props) => <Toast title="Logged in successfully" props={props} />,
          });
        }

        if (joinAClass) {
          setAddToClass(true);
          setLoginV(false);
          // if (classToJoinData.is_private) {
          //   setClassPasswordV(true);
          //   setLoginV(false);
          // } else {
          //   // disclosure.onClose();
          //   // setTrack("tracking");
          //   setAddToClass(true);
          //   setLoginV(false);
          // }
        }
      } else {
        toast.update(toastID.current, {
          position: "top",
          duration: 7000,
          render: (props) => <Toast title={message} props={props} />,
        });
        if (action === "No email found") {
          toast.update(toastID.current, {
            position: "top",
            duration: 7000,
            render: (props) => <Toast title="Creating your account..." props={props} />,
          });
          signUpHandler(profileObject, googleIdentification);
        } else {
          setGoogleErrorMessage(message);
        }

        signOut();
      }
    },
  });

  // ============================================================================

  // Onsubmit function for forms
  const onSubmit = async (data) => {
    try {
      await studentLogin({
        variables: {
          ...data,
        },
      });
    } catch (error) {}
  };

  const responseGoogle = async (response: any) => {
    const { googleId, profileObj, error } = response;

    // show authentication message
    if (googleId) {
      toastID.current = toast({
        position: "top",
        duration: 7000,
        render: (props) => (
          <Toast
            title={!error ? `Authenticated as ${profileObj.name}` : "We're unable to authenticate with your Google Account"}
            props={props}
          />
        ),
      });
    }

    // Call login mutation and pass in data gotten from google(name, email, auth_type, google_id)
    if (!error) {
      setGoogleIdentification(googleId);
      setProfileObject(profileObj);
      try {
        await googleLogin({
          variables: {
            name: profileObj.name,
            googleId: googleId,
            email: profileObj.email,
            auth_type: "STUDENT_LOGIN",
          },
        });
      } catch (err) {
        toast.update(toastID.current, {
          position: "top",
          duration: 7000,
          render: (props) => <Toast title={err.message} props={props} />,
        });

        return null;
      }
    }

    // If an error occured
    if (error) {
      setErrorMessage(error.message);
    }

    // name, googleId, email, authtype
    // alert('done');
  };

  // let REDIRECT_URI = process.env.NODE_ENV === "development" ? "https://coyani.vercel.app" : "https://learntific.com";

  // const REDIRECT_URI =
  // 	!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  // 		? 'http://localhost:3000/testPage'
  // 		: process.env.NEXT_PUBLIC_ORIGIN;

  const REDIRECT_URI =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "https://learntific.vercel.app/login"
      : "https://learntific.com/login";
  return (
    <Fragment>
      {/*  */}
      <Box w="100%" px={{ md: 10 }} mt={10} bg="blue.500">
        {/* Login functionality */}
        {loginV && (
          <Fragment>
            {/* Heading */}
            <Heading color="white" mb={3} fontWeight="900" fontSize={{ base: "2xl", md: "1.75rem" }} textAlign="center">
              Login to your Learntific Account
            </Heading>

            {/* Google login */}
            <GoogleLogin
              clientId={clientId}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              uxMode="redirect"
              isSignedIn={true}
              redirectUri={`${REDIRECT_URI}`}
              render={(renderProps) => (
                <Button
                  fontSize="sm"
                  w="100%"
                  size="lg"
                  onClick={renderProps.onClick}
                  px={6}
                  fontWeight={700}
                  colorScheme="green"
                  borderRadius="lg"
                  color="blue.500"
                  isLoading={googleLoading || googleSignupLoading}
                  // onClick={() => signIn('google')}
                  // isLoading={loading}
                  disabled={googleLoading || normalLoading}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4 10C4 13.3137 6.68629 16 10 16C12.6124 16 14.8349 14.3304 15.6586 12H10V8H19.8047V12H19.8C18.8734 16.5645 14.8379 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C13.445 0 16.4831 1.742 18.2815 4.39318L15.0039 6.68815C13.9296 5.06812 12.0895 4 10 4C6.68629 4 4 6.68629 4 10Z"
                      fill="currentColor"
                    />
                  </svg>

                  <Text as="span" ml={2}>
                    Continue with Google
                  </Text>
                </Button>
              )}
            />
            {/* <Button
							fontSize="sm"
							w="100%"
							size="lg"
							px={6}
							fontWeight={700}
							colorScheme="blue"
							borderRadius="lg"
							onClick={() => signIn('google', { callbackUrl: 'http://localhost:3000/classes/asckjas' })}
							// isLoading={loading}
							disabled={normalLoading}
						>
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M4 10C4 13.3137 6.68629 16 10 16C12.6124 16 14.8349 14.3304 15.6586 12H10V8H19.8047V12H19.8C18.8734 16.5645 14.8379 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C13.445 0 16.4831 1.742 18.2815 4.39318L15.0039 6.68815C13.9296 5.06812 12.0895 4 10 4C6.68629 4 4 6.68629 4 10Z"
									fill="currentColor"
								/>
							</svg>

							<Text as="span" ml={2}>
								Continue with Google
							</Text>
						</Button> */}
            {googleErrorMessage && (
              <Box color="green.500" justifyContent="center" mt={2} d="flex" mb={2} fontWeight="bold" fontSize="sm">
                <Text mr={1} fontSize="lg">
                  <HiExclamationCircle />
                </Text>
                <Text>{googleErrorMessage}</Text>
              </Box>
            )}

            <Text textAlign="center" fontWeight="700" color="blue.300" my={4}>
              or
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
              <HStack flexDir={{ base: "column", md: "row" }} w="100%" spacing={{ base: 0, md: "12px" }}>
                {/* Email */}
                <DarkFormControlInput
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="hilda@email.com"
                  errors={errors.email}
                  ref={register({
                    required: "We need your email to log you in.",
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Your email dosen't look right",
                    },
                  })}
                />

                {/* Password */}
                <DarkFormControlInput
                  type="password"
                  name="password"
                  label="Password"
                  reset={true}
                  placeholder="•••••••••"
                  resetHandler={() => {
                    goToReset();
                  }}
                  errors={errors.password}
                  ref={register({
                    required: "Your password is necessary to log you in.",
                  })}
                />
              </HStack>

              {errorMessage && (
                <Box d="flex" mb={2} color="green.500" fontWeight="bold" fontSize="sm">
                  <Text mr={1} fontSize="lg">
                    <HiExclamationCircle />
                  </Text>
                  <Text>{errorMessage}</Text>
                </Box>
              )}

              <Button
                mt={2}
                w="100%"
                type="submit"
                colorScheme="gray"
                variant="solid"
                fontSize="sm"
                size="lg"
                isLoading={normalLoading}
                px={6}
                py={6}
                onClick={() => {
                  setErrorMessage(null);
                }}
                fontWeight={700}
                borderRadius="lg"
                mb={4}>
                Continue with Email
              </Button>
            </form>
            <Flex justify="center" mt={{ lg: 8 }} mb={{ base: 8, md: 0 }}>
              <FormLink
                bg="blue.600"
                color="white"
                borderBottomColor="white"
                fontWeight={700}
                onClick={() => router.push("/signup")}
                title="I don't have an account"
              />
            </Flex>
          </Fragment>
        )}
        {/* Add to class functionality */}
        {addToClassH && <AddToClass toastID={toastID} />}
        {/* {classPasswordV && <ClassPassword fromAuth={true} />} */}
        {showSignup && <Signup isPage={false} justLogin={justLogin} joinAClass={joinAClass} {...showProps} />}
      </Box>
    </Fragment>
  );
};

export default Login;
