// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//import * as firebase from 'firebase';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  // apiKey: "AIzaSyBx1cAgj5ZcAKg8SOhJmJ6F9dFrDF4qxU8",
  // authDomain: "coyani.firebaseapp.com",
  // databaseURL: "https://coyani.firebaseio.com",
  // projectId: "coyani",
  // storageBucket: "coyani.appspot.com",
  // messagingSenderId: "540878263087",
  // appId: "1:540878263087:web:9b8c42a64bc4b2e76cc7da",
  // measurementId: "G-M2FV530CM9",
  apiKey: process.env.NEXT_PUBLIC_FIRESTORE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIRESTORE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIRESTORE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIRESTORE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
